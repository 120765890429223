<template>
	<div>
	<ContentHeader title="Tindakan" />
     <div class="content-header">
      <div class="container-fluid">

        <div class="card" v-if="cabang?.tipe?.toLowerCase() != 'kecantikan'">
          <div class="card-header">
            <h3 class="card-title"><router-link to="/data-tindakan"><div style="#007bff">Data Tindakan</div></router-link></h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body" style="display: block;">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">KODE</th>
                  <th scope="col">NAMA</th>
                  <th scope="col">JENIS</th>
                  <th scope="col">KATEGORI</th>
                  <th scope="col">TARIF UMUM</th>
                  <th scope="col">TARIF MEMBER</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in tindakan" :key="row.id">
                  <td scope="row">{{row.kode_tindakan}}</td>
                  <td>{{ row.nama }}</td>
                  <td>{{ row.jenis_tindakan }}</td>
                  <td>{{ row.kategori }}</td>
                  <td>Rp. {{ number_format(row.harga_pasien_baru, 0, ',', '.')  }}</td>
                  <td>Rp. {{ number_format(row.harga_pasien_member, 0, ',', '.')  }}</td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_tindakan">Memuat Data</p>
            <p class="text-center mt-2">{{ cekData }}</p>
          </div>
            <!-- /.card-body -->
            <div class="card-footer" style="display: block;">
              
            </div>
            <!-- /.card-footer-->
        </div>

        <div class="card" v-if="cabang?.tipe?.toLowerCase() == 'kecantikan'">
          <div class="card-header">
            <h3 class="card-title"><router-link to="/data-tindakan-kecantikan"><div style="#007bff">Data Tindakan Kecantikan</div></router-link></h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body" style="display: block;">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">KODE</th>
                  <th scope="col">NAMA</th>
                  <th scope="col">JENIS</th>
                  <th scope="col">KATEGORI</th>
                  <th scope="col">TARIF UMUM</th>
                  <th scope="col">TARIF MEMBER</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in tindakan_kecantikan" :key="row.id">
                  <td scope="row">{{row.kode_tindakan}}</td>
                  <td>{{ row.nama }}</td>
                  <td>{{ row.jenis_tindakan }}</td>
                  <td>{{ row.kategori }}</td>
                  <td>Rp. {{ number_format(row.harga_pasien_baru, 0, ',', '.')  }}</td>
                  <td>Rp. {{ number_format(row.harga_pasien_member, 0, ',', '.')  }}</td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_tindakan_kecantikan">Memuat Data</p>
            <p class="text-center mt-2">{{ cekDataKecantikan }}</p>
          </div>
            <!-- /.card-body -->
            <div class="card-footer" style="display: block;">
              
            </div>
            <!-- /.card-footer-->
        </div>


            <div class="card">
              <div class="card-header">
                <h3 class="card-title"><router-link to=""><div style="#007bff">Setup Point Reward</div></router-link></h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body" style="display: block;">
                <div class="row align-items-center">
                  <div class="col">
                    <p>Transaksi Treatment Senilai</p>
                  </div>
                  <div class="col">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="btn btn-secondary">Rp.</div>
                      </div>
                      <!-- /btn-group -->
                        <input type="number" class="form-control" step="any" v-model="point_reward.transaksi_tindakan">
                    </div>
                  </div>
                  <div class="col">
                    <p>Mendapatkan Point</p>
                  </div>
                  <div class="col">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="btn btn-secondary">Sejumlah</div>
                      </div>
                      <!-- /btn-group -->
                        <input type="number" class="form-control" step="any" v-model="point_reward.point_reward_tindakan">
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col">
                    <p>Pembelian Produk Senilai</p>
                  </div>
                  <div class="col">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="btn btn-secondary">Rp.</div>
                      </div>
                      <!-- /btn-group -->
                        <input type="number" class="form-control" step="any" v-model="point_reward.transaksi_resep_obat">
                    </div>
                  </div>
                  <div class="col">
                    <p>Mendapatkan Point</p>
                  </div>
                  <div class="col">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="btn btn-secondary">Sejumlah</div>
                      </div>
                      <!-- /btn-group -->
                        <input type="number" class="form-control" step="any" v-model="point_reward.point_reward_resep_obat">
                    </div>
                  </div>
                </div>
                <button type="submit" @click.prevent="prosesSetupPointReward" class="btn btn-primary d-flex align-items-center mt-2">
                    <div>
                      Proses
                    </div>  
                    <div>
                      <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <g>
                          <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
                          <path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
                          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </g>

                      </svg>
                    </div>
                  </button>
              </div>
              <!-- /.card-body -->
              <div class="card-footer" style="display: block;">
                
              </div>
              <!-- /.card-footer-->
            </div>

            <div class="card">
              <div class="card-header">
                <h3 class="card-title"><router-link to="/data-voucher"><div style="#007bff">Setup Voucher</div></router-link></h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body" style="display: block;">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">KODE</th>
                      <!-- <th scope="col">JUMLAH</th> -->
                      <th scope="col">NOMINAL</th>
                      <th scope="col">MASA BERLAKU</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in voucher" :key="row.id">
                      <td scope="row">{{ row.kode_voucher }}</td>
                      <!-- <td>{{ row.jumlah }}</td> -->
                      <td>{{ format_nominal(row.nominal) }}</td>
                      <td>{{ format_tanggal(row.masa_berlaku.slice(0, 10)) }}</td>
                    </tr>
                  </tbody>
                </table>
              <p class="text-center mt-2">{{ cekDataVoucher }}</p>
              </div>
              <!-- /.card-body -->
              <div class="card-footer" style="display: block;">
                
              </div>
              <!-- /.card-footer-->
            </div>

		</div>
	</div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'

import axios from 'axios'
import store from '@/store'
import { ref, onMounted, computed, reactive } from 'vue' 
import number_format from '@/helper.js'
import format_tanggal from '@/format_tanggal.js'
import format_nominal from '@/format_nominal.js'
import Swal from 'sweetalert2'

const user = computed(() => store.getters['auth/user'])
const cabang = computed(() => store.getters['auth/cabang'])
      
const loading = ref(false)
const point_reward = reactive({
  'cabang_id' : '',
  'transaksi_tindakan' : '',
  'transaksi_resep_obat' : '',
  'point_reward_tindakan' : '',
  'point_reward_resep_obat' : '',
})
const getPointReward = async () => {
  let { data } = await axios.get(`api/point_reward/getWhere/${user.value.cabang_id}`)

  if (data == 'kosong') {
    point_reward.cabang_id = user.value.cabang_id
    point_reward.transaksi_tindakan = ''
    point_reward.transaksi_resep_obat = ''
    point_reward.point_reward_tindakan = ''
    point_reward.point_reward_resep_obat = ''
  } else {
    point_reward.cabang_id = data.cabang_id
    point_reward.transaksi_tindakan = data.transaksi_tindakan
    point_reward.transaksi_resep_obat = data.transaksi_resep_obat
    point_reward.point_reward_tindakan = data.point_reward_tindakan
    point_reward.point_reward_resep_obat = data.point_reward_resep_obat
  }
}

const prosesSetupPointReward = () => {
  loading.value = true
  axios.put(`api/point_reward/update/${point_reward.cabang_id}`, point_reward)
  .then((response) => {
    Swal.fire({
      title: 'Berhasil!',
      text: 'Berhasil Mengupdate Point Reward',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500
    })  
    loading.value = false
    getTindakan()
    getVoucher()
    getPointReward()
    console.log(response)
  })
  .catch((error) => {
    Swal.fire({
      title: 'Gagal!',
      text: 'Gagal Mengupdate Point Reward',
      icon: 'error',
      confirmButtonText: 'Ok'
    })  
    loading.value = false
    console.log(error)
  })
}

const { tindakan, cekData, getTindakan, memuat_tindakan } = useGetTindakan(user)
const { tindakan_kecantikan, cekDataKecantikan, getTindakanKecantikan, memuat_tindakan_kecantikan } = useGetTindakanKecantikan(user)

const { voucher, getVoucher, cekDataVoucher } = useGetVoucher(user)


onMounted(() => {
  getTindakan()
  getTindakanKecantikan()
  getVoucher()
  getPointReward()
})


function useGetTindakan (user) {
  const tindakan = ref([])
  const cekData = ref()
  const memuat_tindakan = ref(true)

  const getTindakan = async () => {
    let response = await axios.get(`api/tindakan/get/${user.value.cabang_id}/${5}/emptyParameter`)
  
    
    if (response.data == 'kosong') {
      cekData.value = 'Data Masih Kosong'
      tindakan.value = null
      memuat_tindakan.value = false
    } else {
      cekData.value = ''
      tindakan.value = response.data.data
      memuat_tindakan.value = false
    }
  }

  return {
    tindakan,
    cekData,
    getTindakan,
    memuat_tindakan 
  }
}

function useGetTindakanKecantikan (user) {
  const tindakan_kecantikan = ref([])
  const cekDataKecantikan = ref()
  const memuat_tindakan_kecantikan = ref(true)

  const getTindakanKecantikan = async () => {
    let response = await axios.get(`api/tindakan_kecantikan/get/${user.value.cabang_id}/${5}/emptyParameter`)
    
    if (response.data == 'kosong') {
      cekDataKecantikan.value = 'Data Masih Kosong'
      tindakan_kecantikan.value = null
      memuat_tindakan_kecantikan.value = false
    } else {
      cekDataKecantikan.value = ''
      tindakan_kecantikan.value = response.data.data
      memuat_tindakan_kecantikan.value = false
    }
  }

  return {
    tindakan_kecantikan,
    cekDataKecantikan,
    getTindakanKecantikan,
    memuat_tindakan_kecantikan 
  }
}

function useGetVoucher (user) {
  const voucher = ref([])
  const cekDataVoucher = ref()

  const getVoucher = async () => {
    let response = await axios.get('api/voucher/get/' + user.value.cabang_id)
    
    if (response.data == 'kosong') {
      cekDataVoucher.value = 'Data Masih Kosong'
      voucher.value = null
    } else {
      cekDataVoucher.value = ''
      voucher.value = response.data
    }
  }

  return {
    voucher,
    cekDataVoucher,
    getVoucher, 
  }  
}


</script>

<style>
	
</style>